import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Router } from '@angular/router';
import {environment} from '../../environments/environment'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
  providedIn: "root"
})
export class InstalacionesService {

  public activeLang;

  constructor(private http: HttpClient, private router: Router, private translate: TranslateService) {

    this.translate.setDefaultLang(this.activeLang);
  }

  mostrar_instalaciones():Observable<any>{
    return this.http.get(environment.servidor+"/instalaciones")
  }

  mostrar_instalaciones_filtro_por_nombre(id:any):Observable<any>{
    return this.http.get(environment.servidor+"/instalaciones/"+id)
  }

  mostrar_nodos_select(id:any):Observable<any>{
    return this.http.get(environment.servidor+"/nodos/"+id)
  }

  mostrar_modelo_3d(id:any):Observable<any>{
    return this.http.get(environment.servidor+"/modelo/"+id)
  }

  mostrar_nodos_data(id:any):Observable<any>{
    return this.http.get(environment.servidor+"/nodosdata/"+id)
  }

  mostrar_nodos_data_general(id:any):Observable<any>{
    return this.http.get(environment.servidor+"/nodosdatageneral/"+id)
  }

  public cambiarLenguaje(evento) {
    //console.log(evento)
    this.activeLang = evento;
    this.translate.use(evento);
    localStorage.setItem('idioma', evento);

    console.log(this.activeLang)
    
    window.location.reload();
    
  }

}