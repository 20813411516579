import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Router } from '@angular/router';
import {environment} from '../../environments/environment'

@Injectable({
  providedIn: "root"
})
export class UsersService {
  constructor(private http: HttpClient, private router: Router) {}

  login():Observable<any>{
    return this.http.get(environment.servidor+"/login")
  }

  /*login(user: any): Observable<any> {
    return this.http.post("https://reqres.in/api/login", user);
  }*/

  logout(){

    localStorage.setItem('login', 'false');
    this.router.navigate(['/pages/login']);
  }

  info(){

  localStorage.setItem('login', 'false');
    this.router.navigate(['/pages/guia-de-la-plataforma']);
  }
}