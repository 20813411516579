import { Component, ViewChild } from '@angular/core';
import {UsersService} from '../../../services/users.service';
import { InstalacionesService} from "../../../services/instalaciones.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed  #cabecera>
        
      
        <ngx-header class="header" >
        </ngx-header>
        <ng-content select="nb-menu"></ng-content>
      </nb-layout-header>

       <!--<nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
           <ng-content select="nb-menu"></ng-content>
           <div class="redes">
           <a href="https://www.linkedin.com/company/emac-grupo" target="_blank" class="linkedin"><img src="../../../../assets/images/linkedin.png"></a>
           <a href="https://www.youtube.com/channel/UCk39ga0Ucq6wPDcWFju9BFQ" target="_blank" class="youtube"><img src="../../../../assets/images/youtube.png"></a>
           </div>
      </nb-sidebar>-->

      <nb-layout-column>
      <div class="barra-roja">
         <nb-icon icon="person-outline" class="usuario"></nb-icon>
         <p class="saludo">{{ 'cabecera.saludo' | translate }}, buildingeye.es</p>
        <button class="btn-salir" (click)="logout()"><nb-icon icon="log-out-outline" class="salir"></nb-icon></button>
        <button class="btn-info" (click)="info()"><nb-icon icon="info-outline" class="info"></nb-icon></button>
        <div class="col-md-5 selectIdioma">
          <select (change)="onChange($event.target.value)" class="idiomas" #selectIdiomas>
              
              <option style="background-color: #e51e29" [ngClass]="{'active': activeLang == 'es'}">es</option>
              <option style="background-color: #e51e29" [ngClass]="{'active': activeLang == 'en'}">en</option>
              <option style="background-color: #e51e29" [ngClass]="{'active': activeLang == 'fr'}">fr</option>
              <option style="background-color: #e51e29" [ngClass]="{'active': activeLang == 'it'}">it</option>
          </select>
          <i></i>
        </div>
       
      </div>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer>
        </ngx-footer>
        <a class="logo-footer" href="https://emac.es/" target="_blank" hypertag="'Emac'"><img src="../../../../assets/images/logos/EMAC-logo-web-sin-fondo.png"></a>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {

  cogerLogin = localStorage.getItem("login");

  @ViewChild('cabecera', { static: true }) cabecera;

  public activeLang = 'es';

  constructor( private usersService: UsersService, private InstalacionesService:InstalacionesService, private translate: TranslateService){


    this.activeLang = localStorage.getItem("idioma")
        //console.log( this.activeLang)

        this.translate.setDefaultLang(this.activeLang);
  }

  ngOnInit(){


    //this.popup1.nativeElement.style.visibility = 'visible';

   localStorage.setItem('idioma', 'es');

    if(this.activeLang == "es"){

      document.querySelector("select.idiomas > option:nth-child(1)").setAttribute("selected", "")

    }else if(this.activeLang == "en"){

      document.querySelector("select.idiomas > option:nth-child(2)").setAttribute("selected", "")

    }else if(this.activeLang == "fr"){

      document.querySelector("select.idiomas > option:nth-child(3)").setAttribute("selected", "")

    }else if(this.activeLang == "it"){

      document.querySelector("select.idiomas > option:nth-child(4)").setAttribute("selected", "")

    }

  }

  logout(){
    this.usersService.logout();
    //.popup1.nativeElement.style.visibility = 'visible';
  }

  info(){
    this.usersService.info();
  }

  onChange(evento){

    this.InstalacionesService.cambiarLenguaje(evento)

  }
}
